<template>
  <div class="lesson_edit">
    <!-- <headers></headers> -->
    <div class="lesson_wrapper manage-wrapper">
      <!-- <el-breadcrumb separator="/" class="bread-crumb">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/home/manage/project' }">项目课管理</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/lesson/' + courseID }">课节管理</el-breadcrumb-item>
        <el-breadcrumb-item>编辑课节</el-breadcrumb-item>
      </el-breadcrumb> -->
      <div class="tabs__header edit-container">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="编辑课节" name="edit">
            <router-view></router-view>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
  export default {
    data() {
      return {
        activeName: "edit",
        courseID: 0,
      };
    },
    methods: {
      handleClick(tab, event) {
        //   console.log(tab, event);
      },
      async getLessonInfo() {
        let id = this.$route.params.id;
        let resData = await this.$Api.Course.getLesson(id);
        this.courseID = resData.data.courseProjectId
      },
    },
    mounted() {
      this.getLessonInfo();
    },
    activated() {
      this.getLessonInfo();
    }
  };
</script>

<style lang="less" scoped>
@import "../less/lesson_commen.less";
</style>